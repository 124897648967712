html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100vh;
  overflow: auto;
}

.puzzle-game-main-wrapper {
  height: auto;
  background-color: #e8e8e6;
  border-radius: 43px;
}
.puzzle-game-main-wrapper .intro {
  display: block;
  position: relative;
  margin: 0 auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
@media only screen and (max-width: 609px) {
  .puzzle-game-main-wrapper .intro {
    width: 100%;
  }
}
.puzzle-game-main-wrapper .intro .background-one {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.puzzle-game-main-wrapper .intro .background-one #bckgrnd-top-1 {
  width: 700px;
  height: 60.21185px;
}
.puzzle-game-main-wrapper .intro .background-one #bckgrnd-top-1.bckgrnd-top-1_church path {
  fill: #c27958;
}
.puzzle-game-main-wrapper .intro .background-two {
  z-index: -1;
}
.puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1a {
  position: absolute;
  top: 205px;
  left: 19px;
  width: 659.118px;
  height: 192px;
}
.puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1a.bckgrnd-two-1a_church g path {
  fill: #c27958;
}
.puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1b {
  position: absolute;
  top: 225px;
  left: 69px;
  width: 618.48525px;
  height: 136.86375px;
}
.puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1b.bckgrnd-two-1b_church path {
  fill: #dcb3a0;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1a {
    top: 280px;
    left: 49px;
    width: initial;
    height: initial;
  }
  .puzzle-game-main-wrapper .intro .background-two #bckgrnd-two-1b {
    top: 306px;
    left: 116px;
    width: initial;
    height: initial;
  }
}
.puzzle-game-main-wrapper .intro.hide-intro {
  display: none;
}
.puzzle-game-main-wrapper .gameplay {
  display: none;
}
.puzzle-game-main-wrapper .gameplay .background-one-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.puzzle-game-main-wrapper .gameplay .background-one-active #bckgrnd-top-01 {
  width: 700px;
  height: 60.21185px;
}
.puzzle-game-main-wrapper .gameplay .background-one-active #bckgrnd-top-01.bckgrnd-top-01_church path {
  fill: #c27958;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .gameplay .background-one-active #bckgrnd-top-01 {
    width: initial;
    height: initial;
  }
}
.puzzle-game-main-wrapper .gameplay .background-two-active {
  position: absolute;
  top: 180px;
  left: 19px;
  z-index: -1;
}
.puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-1 {
  display: none;
}
.puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-2 {
  display: none;
}
.puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-3 {
  margin-top: -60px;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-1 {
    display: initial;
  }
  .puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-2 {
    display: initial;
  }
  .puzzle-game-main-wrapper .gameplay .background-two-active #bckgrnd-2-3 {
    display: none;
  }
}
@media only screen and (min-width: 699px) and (min-height: 524px) {
  .puzzle-game-main-wrapper .gameplay.active {
    display: initial;
  }
}
.puzzle-game-main-wrapper .gameplay.active {
  display: initial;
  position: unset;
}
.puzzle-game-main-wrapper .title {
  padding-top: 133px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}
.puzzle-game-main-wrapper .title .title-content .main-title {
  font-family: var(--medium-fonts);
  font-size: 24px;
  line-height: 27px;
  padding-top: 12px;
  padding-bottom: 2px;
}
.puzzle-game-main-wrapper .title .title-content .title-more-text {
  font-family: var(--medium-fonts);
  font-size: 24px;
  line-height: 27px;
  padding-left: 3px;
  color: #878787;
  padding-top: 4px;
}
.puzzle-game-main-wrapper .title .title-content .title-more-text.lighter-text {
  font-family: var(--main-fonts);
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.36px;
}
.puzzle-game-main-wrapper .intro-image {
  padding: 82px 0;
}
.puzzle-game-main-wrapper .intro-image img {
  max-width: 420px;
  height: auto;
  width: 100%;
}
.puzzle-game-main-wrapper .hit-play {
  position: relative;
  background: #353535 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 27px 48px;
  margin-bottom: 198px;
  border-radius: 47px;
  font-size: 24px;
  line-height: 27px;
  opacity: 1;
  cursor: pointer;
}
@media only screen and (max-width: 392px) {
  .puzzle-game-main-wrapper .hit-play {
    margin-bottom: 8px;
  }
}
.puzzle-game-main-wrapper .hit-play .animated {
  display: block;
  width: 157px;
  height: 157px;
  -webkit-animation: rotationing 10s infinite;
          animation: rotationing 10s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  position: absolute;
  bottom: -30px;
  left: 525px;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .hit-play .animated {
    width: initial;
    height: initial;
    bottom: -170px;
    left: 770px;
  }
}
.puzzle-game-main-wrapper .hit-play .cartoon-hit-play {
  display: block;
  width: 104.226px;
  height: 99.3572px;
  position: absolute;
  bottom: 0;
  left: 553px;
}
.puzzle-game-main-wrapper .hit-play .cartoon-hit-play.cartoon-hit-play_church #color-control {
  fill: #c27958;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .hit-play .cartoon-hit-play {
    width: initial;
    height: initial;
    bottom: -133px;
    left: 805px;
  }
}
.puzzle-game-main-wrapper .hit-play .on_hover {
  display: none;
}
.puzzle-game-main-wrapper .hit-play:hover {
  -webkit-animation: none;
          animation: none;
}
.puzzle-game-main-wrapper .hit-play:hover .animated {
  display: none;
}
.puzzle-game-main-wrapper .hit-play:hover .cartoon-hit-play {
  display: none;
}
.puzzle-game-main-wrapper .hit-play:hover .on_hover {
  display: block;
  width: 157px;
  height: 157px;
  position: absolute;
  bottom: -30px;
  left: 525px;
}
.puzzle-game-main-wrapper .hit-play:hover .on_hover.on_hover_church #Group_46 #Path_373 {
  fill: #c27958;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .hit-play:hover .on_hover {
    width: initial;
    height: initial;
    bottom: -170px;
    left: 770px;
  }
}
@-webkit-keyframes rotationing {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotationing {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.puzzle-game-main-wrapper .drag-and-drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable {
  height: 139px;
  width: 208px;
  cursor: move;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  z-index: 11;
  display: block;
  border-radius: 24px;
  -ms-touch-action: none;
      touch-action: none;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable {
    height: 72px;
    width: 107px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable .draggable.dragged {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.1;
  cursor: default;
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.church-drag {
  height: 139px;
  width: 208px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.church-drag {
    height: 72px;
    width: 107px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.vertical {
  height: 208px;
  width: 139px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.vertical {
    height: 107px;
    width: 72px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.vertical.church-drag {
  height: 208px;
  width: 139px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.vertical.church-drag {
    height: 107px;
    width: 72px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.square {
  height: 180px;
  width: 180px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.square {
    height: 80px;
    width: 80px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.square.church-drag {
  height: 180px;
  width: 180px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .draggable-elements .draggable.square.church-drag {
    height: 80px;
    width: 80px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 45px;
}
@media only screen and (min-width: 1010px) and (min-height: 760px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins {
    margin-top: 30px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:first-of-type .droppable:first-of-type {
  border-top-left-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:first-of-type .droppable:first-of-type .draggable {
  border-top-left-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:first-of-type .droppable:last-of-type {
  border-top-right-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:first-of-type .droppable:last-of-type .draggable {
  border-top-right-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:last-of-type .droppable:first-of-type {
  border-bottom-left-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:last-of-type .droppable:first-of-type .draggable {
  border-bottom-left-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:last-of-type .droppable:last-of-type {
  border-bottom-right-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements:last-of-type .droppable:last-of-type .draggable {
  border-bottom-right-radius: 20px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable {
  height: 139px;
  width: 208px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: bold;
  background-color: #ffffff;
  -webkit-transition: border-width 0.2s, background-color 0.4s, -webkit-transform 0.2s;
  transition: border-width 0.2s, background-color 0.4s, -webkit-transform 0.2s;
  -o-transition: border-width 0.2s, transform 0.2s, background-color 0.4s;
  transition: border-width 0.2s, transform 0.2s, background-color 0.4s;
  transition: border-width 0.2s, transform 0.2s, background-color 0.4s, -webkit-transform 0.2s;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable {
    height: 72px;
    width: 107px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.droppable-hover {
  background-color: rgb(212, 161, 161);
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.draggable {
  position: initial !important;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.dropped {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.dropped:hover {
  cursor: move;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.vertical {
  height: 208px;
  width: 139px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.vertical {
    height: 107px;
    width: 72px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.square {
  height: 180px;
  width: 180px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins .droppable-elements .droppable.square {
    height: 80px;
    width: 80px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize {
  margin-top: 72px;
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable {
  height: 139px;
  width: 208px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable {
    height: 72px;
    width: 107px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable.vertical {
  height: 208px;
  width: 139px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable.vertical {
    height: 107px;
    width: 72px;
  }
}
.puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable.square {
  height: 180px;
  width: 180px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .drag-and-drop .puzzle-coins.church-customize .droppable-elements .droppable.square {
    height: 80px;
    width: 80px;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle {
  margin-top: 136px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 20px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltip-content-show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltip-content-show .tooltip-text {
  font-size: 19px;
  line-height: 27px;
  margin-top: 4px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: -556px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptexthard {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: -696px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptext.vertical {
  top: -760px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptext.square {
  top: -676px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptexthard.vertical {
  top: -968px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content .tooltiptexthard.square {
  top: -856px;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext .back {
  height: 420px !important;
  width: 628px !important;
  max-width: none;
  border-radius: 20px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext .back {
    height: 216px !important;
    width: 322px !important;
    margin-top: 204px;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext.vertical .back {
  height: 624px !important;
  width: 417px !important;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext.vertical .back {
    height: 321px !important;
    width: 216px !important;
    margin-top: 303px;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext.square .back {
  height: 540px !important;
  width: 540px !important;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptext.square .back {
    height: 240px !important;
    width: 240px !important;
    margin-top: 300px;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard .back {
  height: 560px !important;
  width: 840px !important;
  max-width: none;
  border-radius: 20px;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard .back {
    height: 288px !important;
    width: 428px !important;
    margin-top: 272px;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard.vertical .back {
  height: 832px !important;
  width: 556px !important;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard.vertical .back {
    height: 428px !important;
    width: 288px !important;
    margin-top: 404px !important;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard.square .back {
  height: 720px !important;
  width: 720px !important;
}
@media only screen and (max-width: 1024px) {
  .puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content .tooltiptexthard.square .back {
    height: 320px !important;
    width: 320px !important;
    margin-top: 400px !important;
  }
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content:hover {
  cursor: help;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content:hover .tooltiptext {
  visibility: visible;
}
.puzzle-game-main-wrapper .tooltip-puzzle .tooltip-content.church-content:hover .tooltiptexthard {
  visibility: visible;
}
.puzzle-game-main-wrapper #myBtn,
.puzzle-game-main-wrapper #my2ndBtn,
.puzzle-game-main-wrapper #myBtn2 {
  display: none;
}
.puzzle-game-main-wrapper .modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.76);
  /* Black w/ opacity */
}
.puzzle-game-main-wrapper .modal .modal-content {
  margin-top: 210px;
  border: none;
  background: transparent;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.puzzle-game-main-wrapper .modal .modal-content .congrats-face {
  margin-bottom: -40px;
}
.puzzle-game-main-wrapper .modal .modal-content .congrats-face img {
  width: 128px;
  height: 128px;
}
.puzzle-game-main-wrapper .modal .modal-content .end-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.puzzle-game-main-wrapper .modal .modal-content .end-buttons .play-again,
.puzzle-game-main-wrapper .modal .modal-content .end-buttons .play-other-game {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  padding: 24px 48px;
  border-radius: 37px;
  font-size: 22px;
  line-height: 31px;
}
@media only screen and (max-width: 690px) {
  .puzzle-game-main-wrapper .modal .modal-content .end-buttons .play-again,
.puzzle-game-main-wrapper .modal .modal-content .end-buttons .play-other-game {
    padding: 12px 24px;
    font-size: 18px;
    line-height: 25px;
  }
}
.puzzle-game-main-wrapper .modal .modal-content .background-image {
  position: relative;
  z-index: -1;
  width: 550px;
  height: auto;
  background-color: #a5888e;
  border-radius: 43px;
}
@media only screen and (max-width: 690px) {
  .puzzle-game-main-wrapper .modal .modal-content .background-image {
    width: 300px;
    height: auto;
  }
}
.puzzle-game-main-wrapper .modal .modal-content .background-image .message-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding-bottom: 48px;
}
.puzzle-game-main-wrapper .modal .modal-content .background-image .message-content p {
  padding-top: 10px;
  color: #e8e8e6;
  font-size: 18px;
  line-height: 20px;
}
.puzzle-game-main-wrapper .modal .modal-content .background-image .message-content .message-title {
  margin-top: 58px;
  color: #222222;
  font-size: 24px;
  line-height: 30px;
}
.puzzle-game-main-wrapper .modal .modal-content .background-image .message-content.english-congrats {
  margin-left: 190px;
}
.puzzle-game-main-wrapper .modal.show-modal {
  display: block;
}
.puzzle-game-main-wrapper .do-not-display {
  display: none;
}